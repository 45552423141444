@use '../assets/mixins/media';
@import '../assets/mixins/flex';

.pageFooter {
  height: var(--footer-height);
  border-top: 1px solid var(--color-light-silver);
  margin: 0 35px;
  z-index: 1;
  position: relative;
  @include flex(row, center, center);

  .contactUs {
    right: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .item {
      display: inline-block;
      margin: 0 8px;
    }
  }

  .shortNav {
    .navLink {
      font-size: 15px;
      line-height: 24px;
      text-decoration: none;
      color: var(--color-black-olive);

      &:after {
        content: "";
        position: absolute;
        border-right: 1px solid var(--color-platinum);
        height: 24px;
        margin-left: 20px;
      }

      &:last-child {
        &:after {
          visibility: hidden;
        }
      }
    }
  }

  @include media.max-width(lg) {
    @include flex(column, space-evenly, center);
    border: 0;
    height: 110px;
    margin: 0;

    .contactUs {
      position: initial;
      @include flex(row, center, center);
    }
  }
}