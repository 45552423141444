@use "../assets/mixins/media";

.back {
  position: absolute;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;

  @include media.max-width(md) {
    position: relative;
    margin-bottom: 70px;
  }
}