@use '../assets/mixins/media';
@import '../assets/mixins/flex';

$color-green: #489934;

.wineCellar {

  @include media.max-width(md) {
    padding-left: 0;
    padding-right: 0;
  }

  .wineData {
    grid-template-columns: 290px 1fr;
    grid-template-areas:
      'a b'
	  'a c';

    @include media.max-width(md) {
      grid-template-areas:
        'b b'
        'a c';

      grid-template-columns: 100px 1fr;
      gap: 30px;
      padding: 0 30px;
    }

    .imageContainer {
      grid-area: a;
      height: 280px;
      width: 100px;
      overflow: hidden;

      @include media.max-width(md) {
        height: 220px;
      }

      .image {
        width: 100%;
        height: 320px;
        object-fit: cover;
        margin-top: -20px;

        @include media.max-width(md) {
          height: 260px;
        }
      }
    }

    .title {
      grid-area: b;
      justify-self: left;
    }

    .composition {
      grid-area: c;
      align-self: flex-start;
      gap: 100px;

      @include media.max-width(md) {
        flex-direction: column !important;
        gap: 0;
        align-self: center;
      }

      .list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .item {
          color: #585961;
          font-size: 16px;
          line-height: 36px;

          @include media.max-width(md) {
            line-height: 24px;
          }
        }
      }
    }
  }

  .scrollableTableContainer {
    overflow-x: auto;

    .dateExpire {
      color: #E7443C;
      font-weight: 700;
    }

    .renewalButton {
      cursor: pointer;
    }
  }
}

.storageExtendModal {
  .modalIcon {
    height: 36px;
    width: 36px;

    path {
      fill: var(--color-white);
    }
  }

  .outlineButton {
    --button-color-default: var(--color-white) !important;
    --button-color-hover: var(--color-white) !important;
    --button-color-active: var(--color-white) !important;
    --button-border-color-default: rgba(255, 255, 255, 0.13) !important;
  }
}

.orderDeliveryModal {
  .modalIcon {
    height: 60px;
    width: 60px;

    path {
      fill: var(--color-white);
    }
  }

  .containerName {
    flex-direction: row;
    gap: 12px;

    @include media.max-width(md) {
      flex-direction: column;
    }
  }
}

.conditions {
  gap: 60px;

  @include media.max-width(md) {
    flex-direction: column !important;
    gap: 30px;
  }

  .currentConditions {
    width: 370px;

    @include media.max-width(md) {
      width: 100%;
    }

    .sectionTitle {
      padding: 12px 10px 12px 10px;
      font-size: 16px;
      line-height: 22px;
      @include media.max-width(md) {
        border-bottom: 1px solid var(--color-platinum);
        width: 100%;
        text-align: center;
      }
    }

    .sensorContainer {
      width: 370px;
      gap: 18px;

      @include media.max-width(md) {
        gap: 10px;
      }

      .sensor {
        border: 1px solid var(--color-silver-gray);
        border-radius: 10px;
        padding: 12px;
        width: 169px;
        height: 150px;

        &.lastSensor,
        &:nth-child(3) {
          width: 356px;

          @include media.max-width(md) {
            width: 350px;
          }
        }

        .title {
          font-size: 20px;
        }

        .indicator {
          color: #{$color-green};
          font-size: 46px;
          line-height: 48px;
          font-weight: 700;
          margin-top: 10px;

          &.warning {
            color: var(--color-classic-red);
          }
        }

        .normals {
          color: #585961;
          font-size: 12px;
          line-height: 12px;
        }

        .lastUpdate {
          font-weight: 500;
          margin-top: 12px;
          font-size: 10px;
          color: var(--color-dark-gray);
        }
      }
    }

    .warningBox,
    .dangerBox {
      font-size: 20px;
      font-weight: 700;
      border-radius: 10px;
      padding: 46px;
      text-align: center;
      width: 350px;
    }

    .warningBox {
      color: var(--color-white);
      background-color: var(--color-classic-red);
    }

    .dangerBox {
      color: var(--color-classic-red);
      background-color: #ECECEC;
    }
  }

  .historyFilters {
    padding: 0 12px;

    .item {
      text-decoration: none;
      font-size: 15px;
      color: var(--color-blue);

      &.active {
        font-weight: 500;
      }

      &:after {
        content: "";
        position: absolute;
        border-right: 1px solid var(--color-platinum);
        height: 24px;
        margin-left: 20px;
      }

      &:last-child {
        &:after {
          visibility: hidden;
        }
      }
    }
  }

  .historyList {
    width: 100%;
    max-height: 240px;
    overflow-y: auto;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #ECECEC;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-platinum);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #B1B1B1;
    }

    .row {
      padding: 12px;
      border-bottom: 2px solid var(--color-silver-gray);
      display: flex;

      @include media.max-width(md) {
        display: block;
      }

      .date {
        font-size: 16px;
        font-weight: 500;
        min-width: 180px;
      }

      .indicators {
        font-size: 14px;
        @include flex(row);
        flex-wrap: wrap;
        gap: 3%;
        width: 100%;

        @include media.max-width(md) {
          font-size: 12px;
        }

        .item {
          color: #{$color-green};

          &.warning {
            color: var(--color-classic-red);
          }
        }
      }

      .exception {
        font-size: 14px;
        width: 100%;
        @include flex(row, space-between);


        .item {
          color: var(--color-classic-red);
        }

        .time {
          font-weight: 500;
        }
      }


      .message {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
