@use "../assets/mixins/media";

.main {
  background-color: var(--color-white);
  padding: 155px 30px 60px;
  flex: 1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: -90px;

  @include media.max-width(md) {
    margin-top: 0;
    padding-top: 100px;
  }
}