@use "../assets/mixins/media";
@import "../assets/mixins/flex";

$nav-width: 300px;

.pageHeader {
  position: relative;
  height: var(--header-height);
  padding: 20px 20px 0;
  z-index: 1;

  .navbar {
    padding-top: 20px;

    .mobileProfile {
      display: none;
    }

    .nav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;

      .navItem {
        display: inline-block;
        padding: 0 12px;

        &.separator {
          padding-right: 28px;

          &::after {
            content: "";
            position: absolute;
            border-right: 1px solid var(--color-platinum);
            height: 24px;
            margin-left: 20px;
          }
        }

        &.navLeftAuto {
          margin-left: auto;
        }

        &.cart {
          .text {
            visibility: hidden;
            width: 0;
            height: 0;
          }
        }

        .navLink {
          font-weight: 500;
          font-size: 15px;
          text-decoration: none;
          color: var(--color-black-olive);

          .icon {
            position: relative;

            &.filled {

              &::after {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: var(--color-ligh-blue);
                border-radius: 50%;
                margin-top: -7px;
                margin-right: -5px;
                top: 0;
                right: 0;
              }

              svg {
                path {
                  fill: var(--color-ligh-blue);
                }
              }
            }
          }
        }

        &.dropdown {
          position: relative;
          margin-right: 10px;

          .dropdownToggle {
            padding-right: 5px;
            cursor: pointer;
            color: var(--color-black-olive);

            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 7px;
              width: 10px;
              height: 6px;
              background: url("../assets/images/caret_down.svg") no-repeat;
              transition: transform 300ms;
            }

            &.open {
              &::after {
                transform: scaleY(-1);
              }
            }
          }

          .dropdownMenu {
            position: absolute;
            background-color: var(--color-blue);
            padding: 18px 24px;
            border-radius: 20px;
            right: 0;
            left: 0;
            width: max-content;
            max-width: 200px;
            margin-top: 12px;
            @include flex(column);

            .dropdownLink,
            .language {
              color: var(--color-white);
              text-decoration: none;
              font-size: 15px;
              line-height: 24px;
              padding: 6px 0;
            }

            .language {
              .inputLanguage {
                appearance: none;
              }
            }
          }
        }
      }
    }

    .socialNetworks {
      border-top: 1px solid var(--color-bright-gray);
      padding: 6px 0 36px;
      width: $nav-width;
      margin-top: auto;

      .title {
        color: var(--color-white);
        font-size: 16px;
        line-height: 32px;
        text-align: center;
      }

      .navSocial {

        .socialItem {
          background-color: var(--color-bright-gray);
          border-radius: 5px;
          width: 48px;
          height: 48px;

          .socialLink {
            display: flex;
          }
        }
      }
    }
  }

  .brand {
    position: absolute;
    left: 0;
    right: 0;
    @include flex(row, center);
    pointer-events: none;

    .brandLink {
      pointer-events: auto;
    }
  }

  .hamburgerLines {
    visibility: hidden;
    height: 12px;
    width: 18px;
    position: absolute;
    top: 60px;
    right: 60px;
    z-index: 2;
    @include flex(column, space-between);

    .line {
      display: block;
      height: 2px;
      width: 100%;
      border-radius: 10px;
      background: #333;

      &.line1 {
        transform-origin: 0 0;
        transition: transform 300ms ease-in-out;
      }

      &.line2 {
        transition: transform 200ms ease-in-out;
      }

      &.line3 {
        transform-origin: 0 100%;
        transition: transform 300ms ease-in-out;
      }
    }

    &.open {
      .line {
        &.line1 {
          transform: rotate(40deg);
        }

        &.line2 {
          transform: scaleY(0);
        }

        &.line3 {
          transform: rotate(-40deg);
        }
      }
    }
  }

  @include media.max-width(md) {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    z-index: 5;
    pointer-events: none;
    transition: backdrop-filter 300ms, background-color 300ms;

    &.open {
      // Find a way not to use 'pointer-events' to prevent 'height: 100%'.
      pointer-events: auto;
    }

    .brand {
      top: 40px;

      .logo {
        width: 60px;
        transition: filter 300ms;
      }
    }

    .navbar {
      margin-left: auto;
      max-height: 100vh;
      overflow-y: scroll;
      transition: width 400ms;
      width: 0;
      background-color: var(--color-blue);
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      @include flex(column);

      .nav {
        padding: 24px;
        margin-left: auto;
        z-index: 1;
        flex-direction: column;
        width: $nav-width;
        display: grid;
        grid-template-areas: "a";

        .navItem {
          border-top: 1px solid var(--color-bright-gray);
          padding: 8px 0;
          width: 100%;

          &:first-child {
            border-top: 0;
          }

          &.separator {

            &::after {
              display: none;
            }
          }

          &.navLeftAuto {
            margin-left: 0;
          }

          &.cart {
            grid-area: a;
            border-top: 0;
            border-bottom: 1px solid var(--color-bright-gray);

            .icon {
              display: none;
            }

            .text {
              visibility: visible;
              width: auto;
              height: auto;
            }

            .value {
              font-weight: 500;
            }
          }

          &.navProfile {
            display: none;
          }

          .navLink {
            color: var(--color-white);
            font-size: 16px;
            line-height: 32px;
            font-weight: 300;
          }
        }
      }

      .mobileProfile {
        display: block;
        padding: 96px 0  12px 24px;
        width: $nav-width;

        .userName {
          font-weight: 300;
          font-size: 32px;
          line-height: 32px;
          color: var(--color-bright-gray);
        }

        .userProfile {
          display: inline-block;
          background-color: #D2D2D2;
          border-radius: 12px;
          font-size: 14px;
          line-height: 24px;
          color: var(--color-dark-gray);
          text-decoration: none;
          padding: 4px 18px;
        }
      }
    }

    .hamburgerLines {
      pointer-events: auto;
      visibility: visible;
    }


    &.open {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3);

      .navbar {
        width: 300px !important;
        box-shadow: 0 54px 54px -21px rgba(0, 0, 0, 0.75);
      }

      .brand {
        .logo {
          filter: contrast(0.1) brightness(5);
        }
      }
    }

  }

  @include media.max-width(sm) {
    .brand {
      top: 20px;
    }

    .hamburgerLines {
      top: 24px;
      right: 24px;
      box-sizing: content-box;
      padding: 12px;
    }
  }
}