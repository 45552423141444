//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
@import "variables";

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media.max-width(sm|576) {}
@mixin max-width($max-width) {
  @if map-has-key($breakpoints, $max-width) {
    @media (max-width: map-get($breakpoints, $max-width)) {
      @content;
    }
  } @else if type-of($max-width) == "number" {
    @media (max-width: #{$max-width}px) {
      @content;
    }
  } @else {
    @error "Something went wrong on media-max-width mixin.";
  }
}

@mixin class-by-rule($className, $max-width) {
  @if $max-width {
    @include max-width($max-width) {
      #{"."}#{$className}--#{$max-width} {
        @content;
      }
    }
  } @else {
    #{"."}#{$className} {
      @content;
    }
  }
}
