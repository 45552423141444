@import "./font-onest";
@import "../mixins/flex";
@import "../mixins/general";
@import "animations";

:root {
  --font-main: "Onest", sans-serif;
  --color-classic-red: #CA2200;
  --color-white: #FFFFFF;
  --color-silver-gray: #CCC;
  --color-light-silver: #D7D7D7;
  --color-black-olive: #3B3B3A;
  --color-platinum: #CFCFCF;
  --color-white-50: rgb(255 255 255 / 50%);
  --color-blue: #167FB9;
  --color-ligh-blue: #1699E2;
  --color-light-platinum: #E8E8E8;
  --color-bright-gray: #ECECF4;
  --color-dark-gray: #242528;

  --header-height: 90px;
  --footer-height: 70px;
}

@include text-align;
@include text-colors;

$font-weights: 300, 500, 700;
@include font-weight($font-weights);

@include font-size(((font-size: 20),
    (font-size: 32),
    (font-size: 14, media-max-width: 992),
    (font-size: 16, media-max-width: 992),
    (font-size: 26, line-height: 36, media-max-width: 992),
    (font-size: 30, line-height: 32, media-max-width: 992),
    (font-size: 36, media-max-width: 992),
    (font-size: 36, media-max-width: 576)));

@include max-size(((max-width: 160),
    (max-width: 230),
    (max-width: 360),
    (max-width: 450),
    (max-width: 560),
    (max-width: 760),
    (max-width: 1310),
    (max-height: 210)));

@include margin(((margin-top: 6),
    (margin-top: 12),
    (margin-top: 24),
    (margin-top: 36),
    (margin-top: 48),
    (margin-top: 60),
    (margin-top: 84),
    (margin-top: 120),
    (margin-top: 0, media-max-width: 992),
    (margin-top: 12, media-max-width: 992),
    (margin-top: 24, media-max-width: 992),
    (margin-top: 36, media-max-width: 992),
    (margin-top: 36, media-max-width: 1200),
    (margin-top: 48, media-max-width: 1200),
    (margin-left: 30)));

.desktop-only {
  display: none !important;

  @media screen and (min-width: 992px) {
    display: block !important;
  }
}

.mobile-only {
  display: none !important;

  @media screen and (max-width: 991px) {
    display: block !important;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-main);
}


form,
fieldset {
  width: 100%;
  border: unset;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.link-default,
.link-red {
  font-size: 15px;
  line-height: 24px;
}

.link-default {
  font-weight: 300;
  text-decoration: none;
  color: var(--color-black-olive);
}

.link-red {
  font-weight: 500;
  text-decoration: none;
  color: var(--color-classic-red);
}

.centered-content {
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
}

.center-margin {
  margin-left: auto;
  margin-right: auto;
}

.text-nowrap {
  white-space: nowrap;
}

.table-cellar {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  .thead-cell {
    padding: 12px 10px;
    border-bottom: 2px solid var(--color-light-silver);
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black-olive);
    text-align: left;

    &.h-center {
      text-align: center;
    }
  }

  .tbody-row {
    &:first-child {
      .tbody-cell {
        padding-top: 32px;
      }
    }

    &:hover {
      .tbody-cell {
        background-color: #FAFAFA;
      }

      &[role="link"] {
        cursor: pointer;
      }
    }

    .tbody-cell {
      padding: 12px 10px;
      font-size: 16px;
      color: #667080;
      line-height: 22px;
      vertical-align: top;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      transition: background-color 300ms ease;

      &.h-center {
        text-align: center;
      }

      &.v-center {
        vertical-align: middle;
      }
    }
  }
}