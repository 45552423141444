@use '../assets/mixins/media';
@import "../assets/mixins/flex";

.pageContainer {
  min-height: 100vh;
  background-color: #D9D9D9;
  padding: 10px;
  position: relative;

  .pageContent {
    min-height: calc(100vh - 50px);
    border: 20px solid var(--color-bright-gray);
    border-radius: 45px;
    box-shadow: 0 0 0 3px var(--color-white);
    margin: 10px auto;

    background-color: var(--color-white);
    @include flex(column);
  }

  @include media.max-width(lg) {
    .pageContent {
      border-width: 10px;
      border-radius: 35px;
    }
  }

  @include media.max-width(sm) {
    padding: 6px 0;

    .pageContent {
      border-width: 6px;
      border-radius: 15px;
      box-shadow: 0 0 0 1px var(--color-white);
      margin: 0;
    }
  }
}

