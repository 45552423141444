@use "../assets/mixins/media";
@import "../assets/mixins/flex";


.dropdown {
  position: relative;
  transition: background-color 300ms;

  &.open {
    .dropdownToggle {
      &::after {
        transform: scaleY(-1);
      }
    }
  }

  .dropdownToggle {
    padding-right: 15px;
    cursor: pointer;
    color: var(--color-black-olive);
    display: block;
    font-weight: 500;
    font-size: 15px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 7px;
      width: 10px;
      height: 6px;
      background: url("../assets/images/caret_down.svg") no-repeat;
      transition: transform 300ms;
    }
  }

  .dropdownMenu {
    position: absolute;
    z-index: 1;
    background-color: var(--color-blue);
    padding: 18px 24px;
    border-radius: 20px;
    width: max-content;
    max-width: 200px;
    margin-top: 12px;
    @include flex(column, null, flex-start);

    .dropdownLink {
      color: var(--color-white);
      text-decoration: none;
      font-size: 15px;
      line-height: 24px;
      padding: 8px 0;
      border: 0;
    }

    button.dropdownLink {
      background: none;
      cursor: pointer;
    }
  }

  @include media.max-width(md) {
    margin: 0;

    .dropdownToggle {
      color: var(--color-white);
      line-height: 32px;
      font-weight: 300;
      min-height: 32px;

      &::after {
        filter: contrast(0.1) brightness(5);
        margin-top: 4px;
      }
    }

    .dropdownMenu {
      position: relative;
      width: 100%;
      max-width: 100%;
      background-color: transparent;
      align-items: flex-start;
      padding: 0;

      .dropdownLink {
        color: var(--color-blue);
        font-size: 16px;
        line-height: 32px;
        border: 0;
        width: 100%;
        border-top: 1px solid #CDCDCD;
        text-align: left;
        padding: 8px 12px;
      }
    }

    &.open {
      background-color: var(--color-bright-gray);
      border-radius: 6px;
      margin: 0 -5px -9px;

      .dropdownToggle {
        color: var(--color-blue);
        font-weight: 500;
        margin-top: -9px;
        line-height: 32px;
        padding: 8px 12px;

        &::after {
          margin-top: 12px;
          margin-right: 12px;
          filter: initial;
        }
      }

      .dropdownMenu {
        margin-top: 0;
      }
    }

  }
}