@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src: local("Onest"), url("../fonts/Onest/Onest-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}