.pagination {
  list-style: none;
  margin: 0;
  padding: 0;

  .item {
    text-decoration: none;
    font-size: 15px;
    line-height: 24px;
    color: #979797;
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      border-right: 1px solid var(--color-platinum);
      height: 24px;
      margin-left: 20px;
    }

    &:last-child:after {
      visibility: hidden;
    }

    &.selected {
      color: var(--color-black-olive);
    }

    &.dots {

    }
  }
}