@use "media";
@use "sass:list";
@use "sass:map";
@use "sass:string";

@import "variables";

@mixin text-align() {
  @each $value in $text-align {
    .text-#{$value} {
      text-align: #{$value};
    }
  }
}

@mixin font-weight($weights) {
  @each $weight in $weights {
    .fw-#{$weight} {
      font-weight: #{$weight};
    }
  }
}

/**
 * Example: .fs-20, .fs-20--992, .fs-20-32--992
 */
@mixin font-size($font-sizes) {
  @each $row in $font-sizes {
    @each $key, $value in $row {
      @if not list.index($font-sizes-options, $key) {
        @warn "Unknown key '#{$key}' in 'font-size' mixin.";
      }
    }

    @if not map-has-key($row, font-size) {
      @error "Missing required parameter 'font-size'!";
    }

    $className: fs-#{map.get($row, font-size)};
    @if map-has-key($row, line-height) {
      $className: #{$className}-#{map.get($row, line-height)};
    }

    @include media.class-by-rule($className, map.get($row, media-max-width)) {
      font-size: #{map.get($row, font-size)}px;
      @if map-has-key($row, line-height) {
        line-height: #{map.get($row, line-height)}px;
      }
    }
  }
}

@mixin margin($margins) {
  @each $row in $margins {

    @include map-has-only-one($row, $margin-options);

    @each $key in $margin-options {
      @if map.has-key($row, $key) {
        $className: m#{string.slice($key, 8, 8)}-#{map.get($row, $key)};

        @include media.class-by-rule($className, map.get($row, media-max-width)) {
          #{$key}: #{map.get($row, $key)}px;
        }
      }
    }
  }
}

@mixin max-size($max-sizes) {
  @each $row in $max-sizes {

    @include map-has-only-one($row, $max-sizes-options);

    @each $key in $max-sizes-options {
      @if map.has-key($row, $key) {
        $className: M#{string.slice($key, 5, 5)}-#{map.get($row, $key)};

        @include media.class-by-rule($className, map.get($row, media-max-width)) {
          #{$key}: #{map.get($row, $key)}px;
        }
      }
    }
  }
}

@mixin text-colors() {
  $types: ("warning": var(--color-classic-red));
  @each $type, $color in $types {
    .text-#{$type} {
      color: #{$color};
    }
  }
}

@mixin map-has-only-one($row, $options) {
  $i: 0;
  @each $key in map.keys($row) {
    @if list.index($options, $key) {
      $i: $i + 1;
    }
  }
  @if $i == 0 {
    @error "Missing one of the required parameters: #{$options}.";
  } @else if $i > 1 {
    @error "Only one of the following parameters is allowed: #{$options}.";
  }
}

