.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms ease-out;
}

.modal-fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.modal-fade-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}

.step-switch {
  $step-switch-animation-time: 400ms;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity $step-switch-animation-time;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity $step-switch-animation-time;
  }
}

.fade-in {
  animation-duration: 0.4s;
  animation-name: fade-in-animation;

  // animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes fade-in-animation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
